import React from "react";
import {useEffect, useState} from "react";
import {Navbar, Container, Nav} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import navIcon4 from '../assets/img/nav-icon4.svg';
import logo from '../assets/img/fz-logo.png';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }

        window.addEventListener("scroll", onScroll);
        
        return () =>window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) =>{
        setActiveLink(value);
    }

      return (
    <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
          <Container style={{ marginLeft: '70px' }}>
      <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" >
        <span className="navbar-toggle-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeLink ==='home' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
            <Nav.Link href="#skills" className={activeLink ==='skills' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
            <Nav.Link href="#projects" className={activeLink ==='projects' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
             <Nav.Link href="#education" className={activeLink ==='education' ? 'active navbar-link': 'navbar-link'} onClick={() => onUpdateActiveLink('education')}>Education</Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
            <a href="https://www.linkedin.com/in/fardeen-zubair"><img src={navIcon1} alt="" /></a>
            <a href="https://www.facebook.com/fardeen.zubair.5"><img src={navIcon2} alt="" /></a>
            <a href="https://www.instagram.com/fardeeen_z/"><img src={navIcon3} alt="" /></a>
            <a href="https://github.com/Fardeeeeen"><img src={navIcon4} alt="" /></a>

            </div>
            <button className="vvd" onClick={() => window.location.href = '#connect'}><span>Email</span></button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
